$color-purple: #8b5cf6;
$color-pink: #ec4899;
$color-gray: #9ca3af;
$color-black: #1f2937;
$card-size: 23rem;

.carousel {
  position: relative;
  width: $card-size;
  height: $card-size;
  perspective: 500px;
  transform-style: preserve-3d;
}

.card-container {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotateY(calc(var(--offset) * 0deg))
    scaleY(calc(1 + var(--abs-offset) * -0.4))
    translateZ(calc(var(--abs-offset) * -1rem))
    translateX(calc(var(--direction) * -10rem));
  filter: blur(calc(var(--abs-offset) * 0.5rem));
  transition: all 0.6s ease-out;
}

.card {
  width: 100%;
  height: 100%;
  padding: 2rem;
  background-color: hsl(280deg, 40%, calc(100% - var(--abs-offset) * 50%));
  border-radius: 1rem;
  color: $color-gray;
  text-align: justify;
  transition: all 0.6s ease-out;

  h2 {
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    margin: 0 0 0.7em;
    color: $color-black;
  }

  p,
  h2 {
    transition: all 0.6s ease-out;
    opacity: var(--active);
  }
}

.nav {
  color: white;
  font-size: 5rem;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 80%;
  z-index: 2;
  cursor: pointer;
  user-select: none;
  background: unset;
  border: unset;
  transform: translateY(-50%);

  &.left {
    left: -55%;
    transform: translateX(-100%) translatey(-50%);
  }

  &.right {
    right: -65%;
    transform: translateX(100%) translatey(-50%);
  }
}

// .nav {
//     color: white;
//     font-size: 2rem; // Adjust the size of the icons if needed
//     position: absolute;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     top: 50%;
//     z-index: 2;
//     cursor: pointer;
//     user-select: none;
//     background: unset;
//     border: unset;
//     transform: translateY(-50%); // Center vertically

//     &.left {
//       left: 0; // Ensure the button is at the left edge
//       transform: translateX(-100%) translateY(-50%);
//     }

//     &.right {
//       right: 0; // Ensure the button is at the right edge
//       transform: translateX(100%) translateY(-50%);
//     }
//   }
